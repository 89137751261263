import mnml from '@dryan-llc/mnml.js';
import { currency } from './lib/formatting';
import { apiUrl } from './lib/api';
import { Pledge } from './lib/interfaces';

const campaign = JSON.parse(document.body.dataset.campaign || '{}');
const campaignGroup = JSON.parse(document.body.dataset.campaignGroup || '{}');
let latestPledge: null | Pledge = null;
const user = JSON.parse(document.body.dataset.user || 'null');
const flashPledgeDelay = 30;
const flashPledgeDelayInitial = 10;

export type PrideColors =
  | 'red'
  | 'orange'
  | 'yellow'
  | 'green'
  | 'blue'
  | 'purple';

const getPledgeColor = (): PrideColors => {
  getPledgeColor.currentIndex++;
  if (getPledgeColor.currentIndex >= getPledgeColor.colors.length) {
    getPledgeColor.currentIndex = 0;
  }
  return getPledgeColor.colors[getPledgeColor.currentIndex];
};
getPledgeColor.colors = [
  'red',
  'orange',
  'yellow',
  'green',
  'blue',
  'purple',
] as PrideColors[];
getPledgeColor.currentIndex = -1;

const shownPledges: string[] = [];
const flashPledge = (pledge: Pledge) => {
  if (shownPledges.includes(pledge.id)) {
    return;
  }
  shownPledges.push(pledge.id);
  const banner = mnml.html`
    <pr-pledge-banner pledge-id="${
      pledge.id
    }" variant="${getPledgeColor()}" avatar="${pledge.avatar}" details="${
    pledge.name
  } pledged ${currency(
    parseFloat(pledge.amount),
    pledge.campaign.currency.abbr
  )} per ${pledge.campaign.goal_label} to ${pledge.campaign.name}" action="${
    pledge.campaign.public_url
  }?show_form=1&source=latest-pledge"></pr-pledge-banner>`.querySelector(
    'pr-pledge-banner'
  ) as HTMLElement;
  [...document.body.querySelectorAll('pr-pledge-banner:not(.dismissed)')].map(
    (banner) => banner.classList.add('dismissed')
  );
  document.body.querySelector('.pledge-banners')?.append(banner);
  setTimeout(() => {
    banner.classList.add('dismissed');
  }, (flashPledgeDelay - 2) * 1000);
};

document.addEventListener('visibilitychange', () => {
  if (document.hidden) {
    [...document.querySelectorAll('pr-pledge-banner')].map((banner) => {
      banner.remove();
    });
  }
});

let latestPledgeTimeout: undefined | ReturnType<typeof setTimeout> = undefined;

const fetchLatestPledge = () => {
  clearTimeout(latestPledgeTimeout);
  if (document.hidden) {
    return;
  }

  const params: { [key: string]: any } = {
    limit: 10,
    active_only: 1,
  };
  if (campaign && campaign.id) {
    params.campaign = campaign.id;
    if (latestPledge && latestPledge.campaign.id === campaign.id) {
      params.since = latestPledge.created;
    } else {
      latestPledge = null;
    }
  } else if (campaignGroup && campaignGroup.id) {
    params.campaign_group = campaignGroup.id;
    if (
      latestPledge &&
      latestPledge.campaign.groups.filter(
        (group) => group.id === campaignGroup.id
      ).length
    ) {
      params.since = latestPledge.created;
    } else {
      latestPledge = null;
    }
  } else if (latestPledge) {
    params.since = latestPledge.created;
  } else {
    // pass
  }

  fetch(apiUrl('/pledges/', params))
    .then((response) => response.json())
    .then((response) => {
      latestPledge = response.results
        .filter(
          (p: Pledge) =>
            p.user !== (user ? user.id : 'bogus-user-id') &&
            p.name !== 'Anonymous'
        )
        .shift();
      if (latestPledge) {
        flashPledge(latestPledge);
      }
      latestPledgeTimeout = setTimeout(
        fetchLatestPledge,
        flashPledgeDelay * 1000
      );
    })
    .catch(console.error);
};

const latestPledgeInit = () => {
  if (!document.querySelector('.pledge-banners')) {
    return false;
  }
  latestPledgeTimeout = setTimeout(
    fetchLatestPledge,
    flashPledgeDelayInitial * 1000
  );
  return true;
};

latestPledgeInit();

document.addEventListener('visibilitychange', () => {
  if (!document.hidden) {
    latestPledgeInit();
  }
});
